import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import './UserProfile.css';

const UserProfile = () => {
    const { user } = useAuth();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        if (user) {
            // Here you would typically make an API call to your backend to get user profile data
            // For this example, we'll just simulate it
            setProfile({
                name: user.displayName || 'N/A',
                email: user.email,
                subscription: 'Premium',
                tokens: 1850,
                subscriptionStart: '2024-06-26'
            });
        }
    }, [user]);

    if (!user) {
        return <div>Please log in to view your profile.</div>;
    }

    if (!profile) {
        return <div>Loading profile...</div>;
    }

    return (
        <div className="user-profile">
            <h1>User Profile</h1>
            <div className="profile-info">
                <p><strong>Name:</strong> {profile.name}</p>
                <p><strong>Email:</strong> {profile.email}</p>
                <p><strong>Subscription:</strong> {profile.subscription}</p>
                <p><strong>Tokens Remaining:</strong> {profile.tokens}</p>
                <p><strong>Subscription Start Date:</strong> {profile.subscriptionStart}</p>
            </div>
            <button className="upgrade-btn">Upgrade Subscription</button>
        </div>
    );
};

export default UserProfile;