/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May 2024
 */

import React, { useState, useRef } from 'react'; 
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import { useAuth } from './AuthContext';


const LandingPage = ({setModalOpen}) => {
  const { user, login } = useAuth();
  const inputRef = useRef(null);

  const [projectIdea, setProjectIdea] = useState('');
  const [isInputValid, setIsInputValid] = useState(true); // Add validation state 
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setProjectIdea(e.target.value);
    setIsInputValid(inputValue.length <= 1000); // Check if length is valid 
  };

  // Select Endpoint based on environment
  var HOST_URL = null;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code - run locally
      HOST_URL = 'http://127.0.0.1:8000';
  } else {
      // production code - run on the cloud
      HOST_URL = 'https://studio.techramp.io';
  }

  const API_URL = HOST_URL + '/project-ideas';

  const handleSubmit = async (e) => { // <-- Make handleSubmit an async function
    e.preventDefault();

    if (isInputValid) {
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' 
          },
          body: JSON.stringify({ projectIdea }) 
        });
  
        if (response.ok) {
          console.log('Project idea submitted successfully!');

          // Make the user SignUp/Login before redirecting to the Console
        } else {
          console.error('Error submitting project idea:', response.status); 
          // Handle errors (e.g., display an error message to the user)
        }
      } catch (error) {
        console.error("Error submitting project idea:", error); 
        // Handle errors (e.g., network issues)
      }
    } else {
      console.log('Project idea exceeds 1000 characters');
    }

    sessionStorage.setItem('requestContext', projectIdea); // Store project idea in sessionStorage

    if (user) {
      console.log('User is logged in. Redirecting to Console');
      navigate('/console'); // Redirect to Console
    } else {
      // Redirect to SignUp/Login
      setModalOpen(true);
    }
  };

  return (
    <div className="landing-page-container">
      <div className="landing-page">

        {/* Top bar */}
        {/* Description */}
        <div>
        {/* <p className="description">Your TechRamp AI agents are excited to work for you</p> */}
        <div className="subheading" style={{ display: 'flex', gap: '5px'}}> 
          <span className="welcome">Welcome!</span><span> </span> <span> Meet Your Dream Team</span> 
        </div>
        <p className="description">AI Assistants working 24/7</p>
        
        </div>
        

        {/* Character Rotation */}
        <div className="character-rotation">

          {/* Hipster Section */}
          <div className="character-section">
            <img src="/character_images/Hipster_1.png" alt="Hipster" className="character-icon" />
            <div className="character-info">
              <h2>I'm Hipster</h2>
              <p>I design, and keep it trendy</p>
              <div className="example-prompts-section">
                <p>Try asking me:</p>
                <ul>
                  <li>"Design a logo for my startup"</li>
                  <li>"Create a modern website"</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Hacker Section */}
          <div className="character-section">
            <img src="/character_images/Hacker_1.png" alt="Hacker" className="character-icon" />
            <div className="character-info">
              <h2>I'm Hacker</h2>
              <p>I code, analyze, and refine</p>
              <div className="example-prompts-section">
                <p>Try asking me:</p>
                <ul>
                  <li>"Build a mobile app for my business"</li>
                  <li>"Analyze my website traffic"</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Hustler Section */}
          <div className="character-section">
            <img src="/character_images/Hustler_1.png" alt="Hustler" className="character-icon" />
            <div className="character-info">
              <h2>I'm Hustler</h2>
              <p>I market, pitch, and sell</p>
              <div className="example-prompts-section">
                {/* <img src="/character_images/phone.jpg" alt="Hustler" className="hint-icon" /> */}
                <p>Try asking me:</p>
                <ul>
                  <li>"Create a marketing plan"</li>
                  <li>"Write a sales pitch for my product"</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="project-form">
          <input
            type="text"
            placeholder="Give the team a project to work on..." 
            value={projectIdea}
            onChange={handleInputChange}
            className="project-input"
            maxLength="1000"
          />
          {!isInputValid && ( 
          <div className="error-message">Project idea cannot exceed 1000 characters.</div>
          )}
          <button type="submit" className="idea-submit-button" disabled={!isInputValid}>Send</button> 
        </form> 

        {/* Try Demo Button - Deprecated */}
        {/* <Link to="/demo" className="try-demo-button">Try Demo</Link> Link to Demo Page */}
      </div>
    </div>
  );
};

export default LandingPage;