import React from 'react';
import { Link } from 'react-router-dom';
import './AboutPage.css'; // Import your CSS file


const AboutPage = () => {
  return (
    <div className="about-page-container">
      <HeroSection />
      <CapabilitiesSection />
      <CallToActionSection />
      <MissionVisionSection />
      {/* <TeamSection /> */}

    </div>
  );
};

const HeroSection = () => (
  <section className="hero">
    <img src="/about-us-banner.png" alt="About Us Banner" className="hero-image" />
    <h2>About TechRamp</h2>
    <p>TechRamp is a Technology Company born from a beleif that there has to be a better way for you to live than your typical 9-to-5. Founder Scott Laughlin, inspired by his journey from a young science enthusiast to a seasoned tech entrepreneur, created TechRamp to make cutting edge AI tools accessible to everyone. By simplifying complex tasks and enabling users to reclaim their time, TechRamp is not just a platform—it's a vision of a future where technology serves as a gateway to freedom and creativity.</p>
  </section>
);

const CapabilitiesSection = () => (
  <section className="capabilities">
    <h3>What We Do</h3>
    <p>At TechRamp we scour the earth for the latest advances in AI technology and make it available to you in a slick web interface. We give you a team of AI Assistants enabling you to:</p>
    <ul>
      <li>Creating a business plan</li>
      <li>Write your story or book</li>
      <li>Compose a song</li>
      <li>Create imagery</li>
      <li>Develop code</li>
      <li>Sell a product</li>
    </ul>
    <p>It is like having your own team of programmers, artists, salesforce and project managers working towards your every whim.</p>
  </section>
  
);

const CallToActionSection = () => (
    <section className="call-to-action">
      <Link to="/console" className="cta-button">
        Try TechRamp Studio Now!
      </Link>
    </section>
  );

const MissionVisionSection = () => (
  <section className="mission-vision">
    <div className="mission">
      <h3>Our Mission</h3>
      <p>To empower users with game changing productivity tools, born from a visionary dream to transform lives by breaking the mold of the 9-to-5 grind. TechRamp is dedicated to creating technology that not only simplifies tasks but also opens up new possibilities, allowing users to focus on what truly matters.</p>
    </div>
    <div className="vision">
      <h3>Our Vision</h3>
      <p>To lead a global transformation in AI technology, where innovation is driven by the desire to give people freedom—freedom to innovate, to create, and to live life beyond the ordinary. TechRamp envisions a world where AI tools empower every individual to achieve their greatest aspirations and enable creativity of a scale never before imaginable.</p>
    </div>
  </section>
);

// const TeamSection = () => (
//   <section className="team">
//     <h3>Meet the Team</h3>
//     {/* Add team member profiles here */}
//   </section>
// );



export default AboutPage;