/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May 2024
 */

// // DemoPage.js
import React, { useState, useEffect } from 'react';
import './DemoPage.css'; // Import CSS file for styling
import LoginModal from './LoginModal';

const DemoPage = () => {
    const [demoState, setDemoState] = useState('not_started');
    const [bossMessage, setBossMessage] = useState('');
    const [hipsterMessage, setHipsterMessage] = useState('');
    const [hackerMessage, setHackerMessage] = useState('');
    const [hustlerMessage, setHustlerMessage] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedWorker, setSelectedWorker] = useState(null);

    // const [showAuthModal, setShowAuthModal] = useState(false);
    const [startSignUp, setStartSignUp] = useState(false); // State to control sign-up mode

    const handleWorkerSelect = (workerName) => {
        setSelectedWorker(workerName === selectedWorker ? null : workerName); 
      };

    const toggleModal = () => setModalOpen(!modalOpen);

    const transitionDelay = 1500; // Centralized timing variable for transitions

    useEffect(() => {
        let timer;
        switch (demoState) {
            case 'start_demo':
                timer = setTimeout(() => {
                    setBossMessage("OK everyone, our goal is to create a fun song about a Hipster, a Hacker, and a Hustler.");
                    setDemoState('hipster_step');
                }, transitionDelay);
                break;
            case 'hipster_step':
                timer = setTimeout(() => {
                    setHipsterMessage("Hipster: OK, I'll write some lyrics.");
                    setDemoState('hacker_step');
                }, transitionDelay);
                break;
            case 'hacker_step':
                timer = setTimeout(() => {
                    setHackerMessage("Hacker: OK, I will find a music API we can use.");
                    setDemoState('hustler_step');
                }, transitionDelay);
                break;
            case 'hustler_step':
                timer = setTimeout(() => {
                    setHustlerMessage("Hustler: I'll write some marketing copy to sell our song!");
                    setDemoState('finalize_demo');
                }, transitionDelay);
                break;
            case 'finalize_demo':
                timer = setTimeout(() => {
                    setBossMessage(
                        <>
                        Demo done! Great job everyone! <br /><br /> The Music will start playing for you! <br /><br /> Also here is some marketing copy you can use for your Facebook / Instagram feed: <br /><br /> 🎶🌟 Exciting News Alert! 🌟🎶 <br /><br /> Hey everyone! 🎉 I'm thrilled to announce the release of my latest single, brought to life with the amazing TechRamp Studio! 🚀 This track is a special blend of electrifying beats and soul-touching melodies, all crafted to move you. <br /><br />🎧 Dive into the experience and let the music take you on a journey. Whether you're vibing at home, driving around town, or just needing a musical lift, this song is here for you!<br /><br />Available now on all streaming platforms. 🎵✨ Let's make some noise and spread the word! Share, stream, and enjoy! 📢💖
                        </>);
                    setTimeout(() => {
                        const audioEl = document.getElementsByClassName("audio-element")[0];
                        audioEl && audioEl.play();
                    }, 1000); // Delay to start music after message
                    setDemoState('show_signup');
                    setStartSignUp(true);
                }, transitionDelay);
                break;
            default:
                break;
        }

        return () => clearTimeout(timer);
    }, [demoState]); //This last part is the dependency that triggers the effect when changed

    const handleStart = () => {
        setDemoState('start_demo');
    };

    return (
        <div className="demo-page">
            {/* CEO Panel */}
            <div className="ceo-panel">
                <img src="/character_images/boss_1.png" alt="CEO" className="ceo-image" />
                <h2 className="ceo-title">Hi, I'm Big Boss</h2>
                <div className="prompt">
                    <p className="prompt-title">Give me a project idea and I will lead a team to get it done!</p>
                    <br></br>
                    <p className="prompt-text">Example: Create a song about a hipster, a hacker, and a hustler</p>
                    <p className="ceo-subtext">{bossMessage}</p>
                    {/* Start button */}
                    {demoState === 'not_started' && (
                        <button className="start-button" onClick={handleStart}>Start</button>
                    )}
                    {/* Signup button */}
                    {demoState === 'show_signup' && (
                        <>
                        {modalOpen && <LoginModal close={toggleModal} initialIsNewUser={true} />}
                        <button onClick={toggleModal} className="signup-button">Mobilize Your Own Team</button>
                        </>
                    )}
                </div>
                
            </div>

            {/* Worker Section */}
            <div className="worker-section">

                {/* Hipster Pane */}
                <div 
                    className={`worker-container ${selectedWorker === 'Hipster' ? 'selected' : ''}`} 
                    onClick={() => handleWorkerSelect('Hipster')}
                >
                    <div className="worker-pane"> {/* Content inside the container */}
                        <img src="/character_images/Hipster_1.png" alt="Hipster" className="worker-image" />
                        <p>{hipsterMessage}</p>
                    </div>
                </div>

                {/* Hacker Pane */}
                <div 
                    className={`worker-container ${selectedWorker === 'Hacker' ? 'selected' : ''}`} 
                    onClick={() => handleWorkerSelect('Hacker')}
                >
                    <div className="worker-pane">
                        <img src="/character_images/Hacker_1.png" alt="Hacker" className="worker-image" />
                        <p>{hackerMessage}</p>
                    </div>
                </div>

                {/* Hustler Pane */}
                <div 
                    className={`worker-container ${selectedWorker === 'Hustler' ? 'selected' : ''}`} 
                    onClick={() => handleWorkerSelect('Hustler')}
                >
                    <div className="worker-pane">
                        <img src="/character_images/Hustler_1.png" alt="Hustler" className="worker-image" />
                        <p>{hustlerMessage}</p>
                    </div>
                </div>
            </div>
            <audio className="audio-element">
                <source src="/The Trio of Distinctionv2.mp3" type="audio/mpeg" />
            </audio>
        </div>
    );
};

export default DemoPage;