/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, June 2024
 */

// Bar.js
import './Bar.css'; // Assuming styles for the bar are here
import React, { useState, useEffect } from 'react';
import LoginModal from './LoginModal';
import { useAuth } from './AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa'; 


const Bar = ({ children, isModalOpen, setModalOpen, currentSessionId, setCurrentSessionId, }) => {
  const { user, logout } = useAuth();
  const [sidebarState, setSidebarState] = useState(window.innerWidth > 768 ? 'open' : 'closed');
  const navigate = useNavigate();

  // State variable for sessions
  const [sessions, setSessions] = useState([]);

  // Get the idToken from localStorage
  const [idToken, setIdToken] = useState(localStorage.getItem('idToken'));

  const [loadingSessions, setLoadingSessions] = useState(true);

  // Select Endpoint based on environment
  let HOST_URL = null;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // Development code - run locally
    HOST_URL = 'http://127.0.0.1:8000';
  } else {
    // Production code - run on the cloud
    HOST_URL = 'https://studio.techramp.io';
  }

  const fetchSessions = async () => {
    console.log("Fetching sessions for user:", user.email);
    setLoadingSessions(true);
    if (user && idToken) {
      try {
        const response = await fetch(HOST_URL + '/sessions', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
        });

        console.log("Sessions fetch response:", response);

        if (response.ok) {
          const data = await response.json();
          setSessions(data);

          // Set currentSessionId to the most recent session if it's null
          if (!currentSessionId && data.length > 0) {
            setCurrentSessionId(data[0].conversation_id);
          }

        } else {
          console.error('Error fetching sessions:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    } else {
      // User is not logged in, clear sessions
      setSessions([]);
    }
    setLoadingSessions(false);
  };

  // Fetch sessions when the component mounts and when the user or idToken changes
  useEffect(() => {

    fetchSessions();
  }, [user, idToken]);

  // Update idToken when it changes in localStorage
  useEffect(() => {
    const currentIdToken = localStorage.getItem('idToken');
    setIdToken(currentIdToken);
  }, [user]);

  // Handle session click
  const handleSessionClick = (conversationId) => {
    // Handle navigation to the selected session
    console.log('Selected conversation:', conversationId);
    setCurrentSessionId(conversationId);
  };

  // Handle new session button
  const handleNewSession = async () => {
    if (idToken) {
      try {
        const response = await fetch(`${HOST_URL}/session/new`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          // Fetch sessions again to update the list
          setCurrentSessionId(data.session_id);
          await fetchSessions();
          // Optionally, fetch sessions again to update the list
        } else {
          console.error('Error creating new session:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error creating new session:', error);
      }
    } else {
      console.log('User not authenticated');
    }
  };


  // Function to toggle the sidebar
  const toggleSidebarState = () => {
    setSidebarState(sidebarState === 'open' ? 'closed' : 'open');
  };

  // Add useEffect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
      setSidebarState(window.innerWidth > 768 ? 'open' : 'closed');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // console.log("Bar: Rendering - User", user);
  return (
    <div className={`bar-container ${sidebarState === 'open' ? 'open' : 'closed'}`}>

      {/* Main section where each individual page loads */}
      <div className="main-content">
        <button className={`hamburger-button ${sidebarState === 'open' ? 'open' : 'closed'}`} onClick={toggleSidebarState}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>

        {/* Load the rest of the page */}
        {children} 
      </div>

      {/* Sidebar */}
      <div className={`sidebar-container ${sidebarState === 'open' ? 'open' : 'closed'}`}> 
        <div className={`sidebar ${sidebarState === 'open' ? 'open' : 'closed'}`}>
          <div className="logo-wrapper">
            <Link to="/">
                {sidebarState === 'closed' ? (
                    <img src="/minimized_logo.png" alt="TechRamp Logo" className="logo-icon" /> 
                ) : (
                    <img src="/character_images/logo-01.png" alt="TechRamp Logo" className="logo" />
                )}
            </Link>
          </div>

          <hr className="sidebar-divider" /> {/* <-- Divider line */}


          <div className="minimize-container"> {/* <-- New container */}
            <button onClick={toggleSidebarState} className="minimize-button">
              {sidebarState === 'closed' ? (
                  <img src="/right_arrow_icon.svg" alt="Minimize Sidebar" />
              ) : (
                <img src="/left_arrow_icon.svg" alt="Minimize Sidebar" />
              )}
            </button>
          </div> 

          

          {/* Login/Account section */}
          <div className="memory-section">
          <div className="sessions-header">
                  <h3>Your Sessions</h3>
                  <button onClick={handleNewSession} className="new-session-button" title="New Session">
                  <FaPlus />
                  </button>
          </div>
            {/* Sessions List - Displayed only when user is logged in */}
            {user && sessions.length > 0 && (
              <div className="sessions-list">
    {loadingSessions ? (
      <p>Loading sessions...</p>
    ) : sessions.length > 0 ? (
                <ul>
                  {sessions.map((session) => (
                    <li key={session.conversation_id}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSessionClick(session.conversation_id);
                        }}
                        title={session.summary} // Tooltip with full text
                      >
                        {session.summary}
                      </a>
                    </li>
                  ))}
                </ul>
                ) : (
                  <p>No sessions available.</p>
                )}
              </div>
            )}
          </div>

          {/* Navigation Links */}
          <div className="nav-links-wrapper">
            <div className="nav-links">
              <Link to="/about" className="nav-link">About</Link> 
              | {/* Separator */}

              <div> {/* Wrap Subscription button */}
                <Link 
                  to="/subscriptions" 
                  className="nav-link"
                  onClick={(e) => {
                    if (!user) { // Only prevent default and open modal if not logged in
                      e.preventDefault();
                      setModalOpen(true);
                    } 
                  }}
                >
                  Subscription
                </Link>
              </div>
              |

              <div> {/* LOGIN LINK */}
                {user ? ( // Conditionally render Logout button or Login link
                <>
                  <a href="/" className="nav-link" onClick={logout}> 
                    Logout
                  </a>
                  </>
                ) : (
                  <>
                  <a href="#" className="nav-link" onClick={() => setModalOpen(true)}>Login</a>
                  {isModalOpen && <LoginModal close={() => setModalOpen(false)} />}
                  </>
                )}
              </div>



            </div>
            <hr className="nav-separator" /> {/* Horizontal line */}
          </div>

          {/* Copyright */}
          <div className="copyright">© 2024 TechRamp, LLC - All Rights Reserved</div>
        </div>
      </div>
    </div>
  );
};

export default Bar;