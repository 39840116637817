/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May 2024
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginModal.css';
import { loginUserWithEmail, signUpWithEmail, googleSignIn, facebookSignIn } from './firebaseConfig';
import { useAuth } from './AuthContext';

const LoginModal = ({ close, initialIsNewUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isNewUser, setIsNewUser] = useState(initialIsNewUser); // To toggle between login and sign-up
    const [error, setError] = useState('');
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Hook for navigation
    const { login } = useAuth(); 

    const handleLogin = (e) => {
        e.preventDefault();
        // setLoading(true);
        loginUserWithEmail(email, password)
        .then((userCredential) => {
            console.log("User logged in: ", userCredential);
            login(userCredential.user); // Save the login info in context for other components
            // setLoading(false);
            close();
            navigate('/console'); // Navigate to console page
        })
        .catch((error) => {
            console.log(error);
            setError(error.message);
            alert("Error signing in. Please try again.");
            // setLoading(false);
        });
    };

    const handleSignUp = (e) => {
        e.preventDefault();
        // setLoading(true);
        signUpWithEmail(email, password)
        .then((userCredential) => {
            console.log("User signed up: ", userCredential);
            alert("A verification email has been sent. Please check your inbox.");
            // setLoading(false);
            close();
        })
        .catch((error) => {
            console.log(error);
            setError(error.message);
            alert("Error signing up. Please try again.");
            // setLoading(false);
        });
    };

    return (
        <>
            <div className="login-modal-backdrop" onClick={close}></div>
            <div className="login-modal">
                <button className="close-button" onClick={close}>&times;</button>
                <h2>{isNewUser ? "Sign Up" : "Login"}</h2>
                <div className="button-container">
                    <form onSubmit={isNewUser ? handleSignUp : handleLogin}>
                        <input
                            type="email"
                            className="input-field"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email address"
                            required
                        />
                        <input
                            type="password"
                            className="input-field"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                        />
                        <button type="submit" className="submit-button">
                            {isNewUser ? "Sign Up" : "Login"}
                        </button>
                    </form>
                    <button className="google-button" onClick={googleSignIn}>
                        Sign in with Google
                    </button>
                    <button className="facebook-button" onClick={facebookSignIn}>
                        Sign in with Facebook
                    </button>
                    <p className="toggle-form">
                        {isNewUser ? "Already have an account? " : "Need to create an account? "}  
                    </p>
                    <p className="sign-in-up-mode-link">
                        <span onClick={() => setIsNewUser(!isNewUser)} style={{cursor: 'pointer', color: 'blue'}}>
                                {isNewUser ? "Login here" : "Sign up here"}
                            </span>
                    </p>
                </div>
            </div>
        </>
    );
};

export default LoginModal;