const messages = [
    "Of course, I'll take care of that. The team will get right on it.",
    "Consider it done. I'll have the team handle it.",
    "No problem at all. I'll assign this task to the team immediately.",
    "Leave it to me. The team will start working on it right away.",
    "I'll get that sorted for you. The team will handle it promptly.",
    "Sure thing! I'll delegate this to the team.",
    "Absolutely, I'll ensure the team takes care of it.",
    "Consider it handled. I'll get the team on the job.",
    "I'll take care of that for you. The team will get started immediately.",
    "I'll make sure this is addressed. The team will handle it.",
    "Certainly, I'll assign this to the team right away.",
    "Let me take care of it. The team will be on it in no time.",
    "I'll get this sorted. The team will take over from here.",
    "You got it. The team will handle it.",
    "Leave it to us. The team will take care of it.",
    "Right away. I'll have the team start on this.",
    "I'll see to it that this gets done. The team will handle it.",
    "Sure thing, I'll pass this on to the team.",
    "I'll handle this for you. The team will get right to work.",
    "I'll make sure this is taken care of. The team will handle it.",
    "No worries, I'll have the team handle this.",
    "Absolutely, I'll get the team to work on it.",
    "I'll sort this out for you. The team will handle it.",
    "Let me handle this. The team will take care of it.",
    "I'll see to it that the team handles this.",
    "I'll get this handled for you. The team will take over.",
    "You can count on me. The team will manage it.",
    "I'll ensure this is taken care of. The team will handle it.",
    "I'll take it from here. The team will handle it.",
    "I'll get the team on this right away.",
    "Sure, I'll handle this. The team will get started.",
    "I'll take care of it. The team will handle it.",
    "I'll manage this for you. The team will get on it.",
    "I'll get this sorted. The team will take care of it.",
    "I'll see to it that the team handles this.",
    "I'll ensure this gets done. The team will handle it.",
    "I'll take it from here. The team will handle it.",
    "I'll get this managed. The team will take care of it.",
    "I'll make sure the team handles this.",
    "I'll take care of it. The team will handle it.",
    "I'll see that this is done. The team will manage it.",
    "I'll ensure this is handled. The team will take care of it.",
    "I'll take care of it. The team will handle it.",
    "I'll see that this is managed. The team will handle it.",
    "I'll make sure the team handles this.",
    "I'll handle it. The team will take care of it.",
    "I'll manage this for you. The team will handle it.",
    "I'll see to it that this is done. The team will handle it.",
    "I'll ensure the team takes care of this.",
    "I'll take it from here. The team will handle it.",
    "I'll get this managed. The team will take care of it.",
    "I'll make sure the team handles this.",
    "I'll handle it. The team will take care of it.",
    "I'll see to it that the team manages this.",
    "I'll ensure this is done. The team will handle it.",
    "I'll handle it. The team will take care of it.",
    "I'll see that this is managed. The team will handle it.",
    "I'll make sure the team handles this.",
    "I'll take it from here. The team will handle it.",
    "I'll get this managed. The team will take care of it.",
    "I'll ensure the team handles this.",
    "I'll handle it. The team will take care of it.",
    "I'll take care of it. The team will handle it.",
    "I'll see to it that this is done. The team will manage it.",
    "I'll make sure the team handles this.",
    "I'll take it from here. The team will handle it.",
    "I'll get this managed. The team will take care of it.",
    "I'll handle it. The team will take care of it.",
    "I'll see to it that the team manages this.",
    "I'll ensure this is done. The team will handle it.",
    "I'll take care of it. The team will handle it.",
    "I'll see that this is managed. The team will handle it.",
    "I'll make sure the team handles this.",
    "I'll handle it. The team will take care of it.",
    "I'll take care of it. The team will handle it.",
    "I'll see to it that this is done. The team will manage it.",
    "I'll ensure the team handles this.",
    "I'll take it from here. The team will handle it.",
    "I'll get this managed. The team will take care of it.",
    "I'll handle it. The team will take care of it.",
    "I'll make sure the team handles this.",
    "I'll take it from here. The team will handle it.",
    "I'll manage this. The team will take care of it.",
    "I'll see to it that this is done. The team will handle it.",
    "I'll ensure the team handles this.",
    "I'll handle it. The team will take care of it.",
    "I'll take care of it. The team will handle it.",
    "I'll see to it that this is done. The team will manage it.",
    "I'll ensure the team handles this.",
    "I'll take it from here. The team will handle it.",
    "I'll get this managed. The team will take care of it.",
    "I'll handle it. The team will take care of it.",
    "I'll make sure the team handles this.",
    "I'll take it from here. The team will handle it.",
    "I'll manage this. The team will take care of it.",
    "I'll see to it that this is done. The team will handle it.",
    "I'll ensure the team handles this."
    ];
    export default messages;