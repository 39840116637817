/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, June 2024
 */

import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import './SubscriptionsPage.css'; // Import the CSS for styling

const SubscriptionsPage = ({ setModalOpen }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Select Endpoint based on environment
  var HOST_URL = null;
  var FRONTEND_HOST_URL = null;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code - run locally
    HOST_URL = 'http://127.0.0.1:8000';
    FRONTEND_HOST_URL = 'http://127.0.0.1:3000';
  } else {
    // production code - run on the cloud
    HOST_URL = 'https://studio.techramp.io';
    FRONTEND_HOST_URL = 'https://app.techramp.io';
  }

  const STRIPE_SIGNUP_URL = FRONTEND_HOST_URL + '/subscriptions';
  const USER_SUBSCRIPTION_URL = HOST_URL + '/user-subscription';

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        // Get the idToken from localStorage
        const idToken = localStorage.getItem('idToken');

        if (!idToken) {
          throw new Error('User is not authenticated'); // Or handle differently
        }

        const response = await fetch(USER_SUBSCRIPTION_URL, {
          headers: {
            'Authorization': `Bearer ${idToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching subscription data');
        }

        const data = await response.json();
        setSubscriptionData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchSubscriptionData();
    } else {
      alert("User not signed in, please login first to manage subscriptions");
    }
  }, [user]);

  const handleManageSubscription = () => {
    window.location.assign('https://billing.stripe.com/p/login/9AQ28T07Ea8R0eIfYY');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user) {
    setModalOpen(true);
    return <div>Please log in to manage your subscription.</div>;
  }

  return (
    <div className="subscriptions-page">
        {!subscriptionData || !subscriptionData.plan ? ( 
          //If the user does not have a subscription, display the pricing table
          <div className="pricing-table-container">
            {/* Test Pricing Table */}
            <stripe-pricing-table 
                pricing-table-id="prctbl_1PW2g0P2WQN8fdzqWsLpDton"
                publishable-key="pk_test_51PPsIJP2WQN8fdzqwVx2oS2bvETLTEquCoTnukJckY8LJQfrtBiZQuTE4Am8Ptiu9ImM8wzCLBI5lWJAetIoOEVU00jSAVBn7c"
                client-reference-id={user.uid}
                customer-email={user.email}
                success_url={STRIPE_SIGNUP_URL}
            >
            </stripe-pricing-table>

            {/* Real Pricing Table */}
            {/* <stripe-pricing-table
              pricing-table-id="prctbl_1PW00aP2WQN8fdzq4z6T9LCR"
              publishable-key="pk_live_51PPsIJP2WQN8fdzqDG60QU8phoV1uwEoDIG8OskhKyfHMjGuLqo6vrpMmMTjuPBBH24C59tal7T5JAC8umFT6rJv002IxRaYat"
              client-reference-id={user.uid}
              customer-email={user.email}
              success_url={STRIPE_SIGNUP_URL}
            >
            </stripe-pricing-table> */}
          </div>
        ) : (

          // Wrap the button and subscription details in a div
          <div className="subscription-details">  
            {/* Display subscription details if available */}
            <h2>Your Subscription</h2>
            <p><strong>Plan: </strong>{subscriptionData.plan || "N/A"}</p>
            <p><strong>Tokens:</strong> {subscriptionData.tokens || "N/A"}</p>
            <p><strong>Status:</strong> {subscriptionData.subscription_status || "N/A"}</p>

            <button className="manage-button" onClick={handleManageSubscription}>
              {subscriptionData && subscriptionData.plan
                ? 'Manage Subscription'
                : 'Sign Up'}
            </button>
          </div>
        )}
    </div>
  );
};

export default SubscriptionsPage;