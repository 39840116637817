/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May 2024
 */

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';

// Needed to know which Firebase project to connect to
const config = {
  apiKey: "AIzaSyAgIbLOrxsoBAer_1s4EccrJuWSjmdfgos",
  authDomain: "techrampstudio.firebaseapp.com",
};

const app = initializeApp(config);
const auth = getAuth(app);

const googleSignIn = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

const facebookSignIn = () => {
  const provider = new FacebookAuthProvider();
  return signInWithPopup(auth, provider);
};

const signUpWithEmail = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Send verification email
    await sendEmailVerification(user);
    console.log("Verification email sent!");

    return userCredential; // Return the userCredential if successful
  } catch (error) {
    console.error("Error signing up:", error);
    // Handle signup error (e.g., display an error message to the user)
    throw error; // Re-throw the error to be handled by the caller
  }
};
// const signUpWithEmail = (email, password) => {
//   return createUserWithEmailAndPassword(auth, email, password);
// };

const loginUserWithEmail = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

export { googleSignIn, facebookSignIn, signUpWithEmail, loginUserWithEmail };
