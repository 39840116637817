/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, June 2024
 */

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, getIdToken, signOut } from 'firebase/auth'; // Correct imports

const AuthContext = createContext();

// Export useAuth
export const useAuth = () => useContext(AuthContext); 

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const auth = getAuth();

    // Function to refresh the ID token 
    const refreshToken = async () => {
        if (auth.currentUser) { // Check if user is logged in
        try {
            const freshIdToken = await getIdToken(auth.currentUser, true);
            localStorage.setItem('idToken', freshIdToken); 
            console.log("Token refreshed successfully:", freshIdToken);
            return freshIdToken; // Return the new token
        } catch (error) {
            console.error("Error refreshing token:", error);
            // Handle refresh error (e.g., redirect to login, show error message)
        }
        }
        return null; // Return null if refresh fails or user is not logged in
    };

    // 1. Check local storage for the Firebase User object
    useEffect(() => {
        console.log("DEBUG: Checking local storage for Firebase User object");
        const storedUser = JSON.parse(localStorage.getItem('firebaseUser'));
        if (storedUser) {
            setUser(storedUser);
        }
        setIsLoading(false); // Set loading to false after checking local storage
    }, []);

    // 2. Listen for auth state changes (for login/logout and token refresh)
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            console.log("onAuthStateChanged: Triggered:");
            if (user) {
                console.log("onAuthStateChanged: User signed in:", user.email);
                setUser(user);

                // Refresh and store the ID token
                try {
                    const idToken = await getIdToken(user, true); // Force refresh
                    localStorage.setItem('idToken', idToken);
                    //   console.log("ID Token:", idToken);
                } catch (error) {
                    console.error("Error getting ID token:", error);
                }
            } else {
                console.log("User signed out.");
                setUser(null);
                localStorage.removeItem('idToken'); 
            }
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    // Example: Timer-based proactive refresh - TODO: See if this works
    useEffect(() => {
        let refreshTimer; 
    
        const scheduleTokenRefresh = async () => {
        try {
            const idToken = await getIdToken(auth.currentUser, true); // Force refresh
            localStorage.setItem('idToken', idToken);
            console.log("Token proactively refreshed!");
    
            // Schedule the next refresh (adjust time as needed)
            const tokenExpirationTime = (new Date()).getTime() + (55 * 60 * 1000); // 55 minutes
            const timeUntilRefresh = tokenExpirationTime - Date.now();
            refreshTimer = setTimeout(scheduleTokenRefresh, timeUntilRefresh);
    
        } catch (error) {
            // Handle errors (similar to the onAuthStateChanged error handling)
            console.error("Error during proactive token refresh:", error);
            alert("Error during proactive user token refresh. Please refresh the page.");
        }
        };
    
        // Only schedule if a user is logged in
        if (auth.currentUser) {
        scheduleTokenRefresh();
        }
    
        return () => clearTimeout(refreshTimer); // Clear the timer on unmount 
    }, [auth.currentUser]); 

    const login = async (userData) => {
        try {
            if(userData){
                console.log("DEBUG User data:", userData);}
            else {
                console.error("No user data");
            }
             // Log the user data for debugging
            // Store the Firebase User object in local storage immediately
            localStorage.setItem('firebaseUser', JSON.stringify(userData));
            setUser(userData);

            console.log("DEBUG2")

            // Now you can get the ID token if needed
            const idToken = await userData.getIdToken();
            localStorage.setItem('idToken', idToken); 
            console.log("Login ID Token:", idToken); // Log the token for debugging

            navigate('/console'); 
        } catch (error) {
            console.error("Error getting ID token:", error);
            //TODO: Handle error (e.g., show an error message to the user)
        }
    };

    const logout = () => {
        console.log("logout triggered")
        signOut(auth);
        setUser(null);
        localStorage.removeItem('firebaseUser'); // Clear firebaseUser from local storage
        localStorage.removeItem('idToken');
        console.log("navigating to /")
        navigate('/'); 
        console.log("finished logout")
    };


    // Conditionally render children based on loading state
    return (
        <AuthContext.Provider value={{ user, login, logout, refreshToken }}>
            {isLoading ? <div>Loading...</div> : children} 
        </AuthContext.Provider>
    );
};
